import Company from 'types/company';
import blogs from 'types/blog';
import Carousel from '@components/Carousel';
import Link from 'next/link';
import { useEffect } from 'react';
import { gsap } from 'gsap/dist/gsap';

import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface pageProps {
  companyID: Company;
  blogs: blogs['blogContents'];
}

function Blog({ companyID, blogs }: pageProps): JSX.Element {
  const displayDateFormat = (dateToFormat: string): string => {
    const date = new Date(dateToFormat);
    const daySuffix =
      date.getDate() % 10 == 1 && date.getDate() % 100 != 11
        ? 'st'
        : date.getDate() % 10 == 2 && date.getDate() % 100 != 12
        ? 'nd'
        : date.getDate() % 10 == 3 && date.getDate() % 100 != 13
        ? 'rd'
        : 'th';
    const day = date.getUTCDate() + daySuffix;
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const blogCategoryTheme = (category: string): string => {
    switch (category) {
      case 'Safety First':
        return '#FF9A3E';
      case 'Top Tips':
        return '#FEBA2A';
      case 'Know-How':
        return '#E2A459';
      case '':
        return '#EEB466';
      default:
        return '#666';
    }
  };

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.home-blog',
        start: 'top bottom-=150px',
      },
      defaults: {
        ease: 'back.out(1.2)',
        duration: 1,
      },
    });

    tl.from('.home-blog header', {
      opacity: 0,
      y: 40,
    }).from('.home-blog .blog-post', {
      delay: 0.1,
      opacity: 0,
      y: 60,
      stagger: 0.15,
    });
  }, []);

  return (
    <>
      {blogs.length !== 0 && (
        <section className="home-blog root-container my-16 md:px-12 xl:px-32">
          <header className="flex flex-row w-full items-center mb-8">
            <div>
              <h2 className="text-2xl md:text-3xl text-grey-dark font-normal leading-normal mb-1 md:mb-0">
                From the{' '}
                <Link href="/blog">
                  <a href="#" className="text-black-lightest font-bold">
                    trade blog
                  </a>
                </Link>
              </h2>
              <p className="twrap-balance leading-tight text-lg md:text-xl text-grey-dark">
                Get a head start on your projects with our expert advice, inspiration, and guides
              </p>
            </div>
            <div className="hidden md:flex flex-1 justify-end">
              <Link href="/blog">
                <a className="cta-btn--transparent--option mr-0">Read More</a>
              </Link>
            </div>
          </header>
          <div className="hp-blog-wrapper">
            <Carousel
              companyID={companyID}
              direction={'x'}
              showCarouselOverflow={true}
              hidePrevNextButtons={true}
              mainContainerClass={'gap-6 flex-col md:flex-row sm:flex-nowrap items-stretch'}
              content={blogs.map((blog, index) => (
                <div
                  key={`home-blog-${index}`}
                  className={`relative flex flex-col flex-1 blog-post ${
                    index === 3 && 'hidden xxl:flex'
                  }`}
                >
                  <div
                    className="relative flex flex-col flex-1 justify-end overflow-hidden rounded-2xl px-12 pb-12 pt-16 lg:pt-32 xxl:pt-40 hp-shadow"
                    style={{ isolation: 'isolate' }}
                  >
                    <img
                      src={blog.data.primary_image.url.replace('w=1600&h=1200', 'w=400')}
                      alt=""
                      className="absolute inset-0 h-full w-full object-cover"
                      style={{ zIndex: -10 }}
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-t from-black-light"
                      style={{ zIndex: -10 }}
                    />
                    {/* {blog.tags[0] ? (
                      <span className="absolute top-0 left-0 mt-4 bg-orange text-white rounded-r-lg py-1.5 px-2 pl-12 text-xs leading-none uppercase">
                        {blog.tags[0]}
                      </span>
                    ) : (
                      <span className="absolute top-0 left-0 mt-4 bg-orange text-white rounded-r-lg py-1.5 px-2 pl-12 text-xs leading-none uppercase">
                        Guide
                      </span>
                    )} */}
                    <span
                      className="absolute top-0 left-0 mt-4 text-white rounded-r-full py-1.5 pr-3 pl-20 -ml-8 text-xs leading-none uppercase tag"
                      style={{ backgroundColor: blogCategoryTheme(blog.data.category) }}
                    >
                      {blog.data.category}
                    </span>
                    <div className="text-xs sm:text-14px text-white absolute top-0 right-0 mt-4 pt-1.5 pr-12 sm:mt-0 sm:pt-0 sm:pr-0 sm:static">
                      <time dateTime={displayDateFormat(blog.first_publication_date)}>
                        {displayDateFormat(blog.first_publication_date)}
                      </time>
                    </div>
                    <h3 className="mt-1 text-20px font-bold leading-6 text-white twrap-balance">
                      <a href={`/blog/${blog.uid}`}>
                        <span className="absolute inset-0" />
                        {blog.data.title[0].text}
                      </a>
                    </h3>
                    {/* <div className="blog-card hp-content-card rounded-2xl">
                      <div className="blog-card__inner">
                        <Link href={`/blog/${blog.uid}`}>
                          <a className="blog__link"> </a>
                        </Link>
                        <div className="h-56">
                          <div
                            className=""
                            data-bg={blog.data.primary_image.url.replace('w=1600&h=1200', 'w=400')}
                            style={{
                              backgroundImage: `url(${blog.data.primary_image.url.replace(
                                'w=1600&h=1200',
                                'w=400'
                              )})`,
                            }}
                          >
                            <span className="text-grey-darkest absolute top-0 left-0 ml-8 mt-6 text-sm bg-white rounded-full px-4 py-1">
                              {displayDateFormat(blog.first_publication_date)}
                            </span>
                          </div>
                        </div>
                        <div className="blog__content flex flex-col flex-auto py-6 px-8">
                          <div className="blog__content-inner flex-auto">
                            <h3 className="leading-tight mb-4 text-22px">
                              {blog.data.title[0].text}
                            </h3>
                            <p className="">{blog.data.preview_text[0].text}</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            />
            <div className="section__actions block mt-6 md:hidden">
              <Link href="/blog">
                <a className="cta-btn--transparent--option w-full">Read more blogs</a>
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Blog;
