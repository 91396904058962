// import Carousel from '@components/Carousel';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Link from 'next/link';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import Carousel from '@components/Carousel';
import { RootState } from 'src/redux/store';

import { flameIconList } from '@utils/flameIconList';

gsap.registerPlugin(ScrollTrigger);

const FlameIcon = (): JSX.Element => {
  return (
    <svg
      className="cv-1-17__icon ml-1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6871 4.00094C13.6871 4.00094 13.0496 6.91085 14.6435 9.51161C16.2374 12.1126 21.6792 17.2366 13.9813 22C13.9813 22 15.6114 19.5666 14.7906 17.9053C13.969 16.2442 12.2041 15.0085 12.4983 12.4075C12.4983 12.4075 10.1697 13.4249 11.1993 18.0339C11.1993 18.0339 9.88792 17.5317 9.33583 16.1734C9.33583 16.1734 8.52077 18.8383 10.0161 21.9543C10.0161 21.9543 2.10963 17.9944 8.48382 10.3021C8.48382 10.3021 8.61858 12.8125 9.56261 13.508C9.56261 13.508 8.00566 6.71044 13.6871 4V4.00094Z"
        fill="#EFA847"
      />
    </svg>
  );
};

const Discover = (): JSX.Element => {
  const discoverCategories = [
    // {
    //   link: '/firerated',
    //   imgUrl:
    //     'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/hp-discovery/flame.png',
    //   title: 'Fire Rated Products',
    // },
    {
      link: '/browse/door-furniture/door-handles-and-knobs',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/624371.png',
      title: 'Door Handles & Knobs',
    },
    {
      link: '/browse/sliding-door-gear',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/512029.png',
      title: 'Sliding Door Systems',
    },
    {
      link: '/browse/door-closers-and-fire-exit-hardware/door-closers-and-controls',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/655420.png',
      title: 'Door Closers',
    },
    {
      link: '/browse/sockets-and-wiring/sockets-and-switches',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/189119.png',
      title: 'Sockets & Switches',
    },
    {
      link: '/browse/hinges-seals-and-intumescent/hinges/cabinet-hinges',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/696705.png',
      title: 'Cabinet Hinges',
    },
    {
      link: '/browse/locks-safety-and-security/door-locks-latches-and-security/mortice-locks-and-latches',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/304827.png',
      title: 'Locks & Latches',
    },
    {
      link: '/browse/window-furniture-and-hardware/window-locks-and-security/window-restrictors',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/566462.png',
      title: 'Window Restrictors',
    },
    {
      link: '/browse/locks-safety-and-security/door-locks-latches-and-security/code-operated-locks',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/349241_3.png',
      title: 'Code Locks',
    },
    {
      link: '/browse/hinges-seals-and-intumescent/hinges/fire-door-hinges',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/ironmongery/products/793130_3.png',
      title: 'Fire Door Hinges',
    },
    // {
    //   link: '/free-catalogue',
    //   imgUrl:
    //     'https://img-cdn.manutantraders.com/image/upload/e_background_removal/e_dropshadow:azimuth_180;elevation_50;spread_5/w_300,h_300,f_auto,q_auto:eco/new%20catalogues/Email-Isolated-Catalogue.png',
    //   title: 'Free Catalogue',
    // },
  ];

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const accountDetails = useSelector((state: RootState) => state.user.details);

  useEffect(() => {
    gsap.from('.discover-box', {
      opacity: 0,
      y: 80,
      duration: 0.5,
      delay: 0.5,
      stagger: 0.1,
      ease: 'back.out(1.2)',
    });
  }, []);

  return (
    <section className="home-discover root-container px-8 sm:px-12 xl:px-32 overflow flex flex-col md:flex-row items-center">
      <div className="hidden">
        <p>Need some help?</p>
        <p>
          <a href="#">Speak to an expert</a>
        </p>
      </div>

      <div className="bg-white-darker md:w-1/3 lg:w-1/4 xl:w-1/5 flex-none flex-grow flex z-10 items-center md:-ml-12 xl:-ml-32 md:pl-12 xl:pl-32 mb-6 md:mb-0">
        <h2 className="hidden sm:block w-full text-2xl text-grey-dark font-normal leading-tight sm:leading-normal twrap-balance text-center md:text-left">
          <strong className="text-black-lightest font-bold">The UK{"'"}s biggest range.</strong>{' '}
          Chosen for you
          {accountDetails && accountDetails.firstName && <>, {accountDetails.firstName}</>}.
        </h2>

        <h2 className="w-full sm:hidden text-xl text-grey-dark font-normal leading-tight sm:leading-normal twrap-balance text-center">
          <strong className="text-black-lightest font-bold">The UK{"'"}s biggest range.</strong>
          <br />
          Chosen for you
          {accountDetails && accountDetails.firstName && <>, {accountDetails.firstName}</>}.
        </h2>
      </div>

      <div className="md:w-2/3 lg:w-3/4 xl:w-4/5 z-0">
        <Carousel
          companyID={1}
          direction={'x'}
          showCarouselOverflow={true}
          hidePrevNextButtons={false}
          draggable={true}
          // mainWrapperClass="mt-0 -mx-8 sm:-mx-12 xl:-mx-32 px-8 sm:px-12 xl:px-32"
          mainWrapperClass="mt-0"
          mainContainerClass={'flex flex-row gap-3 sm:gap-6 flex-nowrap relative z-1'}
          content={discoverCategories.map((category, index) => {
            const hasFlameIconLevel = flameIconList.find((item) => item === category.link);
            return (
              <div
                key={`home-blog-${index}`}
                className="flex-none w-1/4 sm:w-160px relative text-black-lightest discover-box"
              >
                <Link href={category.link.toLowerCase()}>
                  <a className="w-full h-full top-0 left-0 absolute"> </a>
                </Link>
                <div className="h-20 flex items-center justify-center">
                  <div className="w-20 h-20">
                    <Link href={category.link.toLowerCase()}>
                      <a>
                        <img src={category.imgUrl} className="h-20 w-20" alt={category.title} />
                      </a>
                    </Link>
                    {hasFlameIconLevel && isMobile && (
                      <div className="relative top-tflame right-rflame">
                        <FlameIcon />
                      </div>
                    )}
                  </div>
                </div>
                <div className="pt-2 p-0">
                  <div
                    className="m-0 text-center transition-colors duration-200 ease-linear"
                    style={{ paddingBottom: '2px' }}
                  >
                    <p className="text-14px sm:text-base font-normal leading-5 twrap-balance">
                      {category.title}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        />
      </div>

      {/* <div className="flex flex-row flex-nowrap gap-6 relative z-1">
        {discoverCategories.map((category, key) => (
          <div
            className="flex-1 relative text-black-lightest hover:text-orange discover-box"
            key={key}
          >
            <Link href={category.link}>
              <a className="w-full h-full top-0 left-0 absolute"> </a>
            </Link>
            <div className="h-20 flex items-center justify-center">
              <div className="w-20 h-20">
                <Link href={category.link}>
                  <a>
                    <img src={category.imgUrl} className="h-20 w-20" alt={category.title} />
                  </a>
                </Link>
              </div>
            </div>
            <div className="pt-2 p-0">
              <div className="m-0 text-center transition-colors duration-200 ease-linear">
                <p className="text-base font-normal leading-tight">{category.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div> */}

      <div className="hidden">
        <Link href="/browse/all">
          <a className="cta-btn--id">
            <span>View Full Range</span>
          </a>
        </Link>
      </div>
    </section>
  );
};

export default Discover;
