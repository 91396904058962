import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
// import { selectDeliveryOption } from 'src/redux/checkoutSlice';
import Company from 'types/company';
// import Campaign from '@components/Campaign';
import CampaignTakeover from '@components/Home/CampaignTakeover';

import Discover from '@components/Home/Discover';
import Bento from '@components/Home/Bento';
import HomeBrands from '@components/HomeBrands';

import { useEffect, useState } from 'react';
import blogs from 'types/blog';
import axios from 'axios';
import campaign from 'types/campaign';
import Head from 'next/head';
import { GetStaticProps } from 'next';
// import SearchBarHero from '@components/SearchBarHero';
import CROTestimonials from '@components/Testimonials/CROTestimonials';
// import HomeReasons from '@components/HomeReasons';
import Blog from '@components/Home/Blog';

import { setDYContext } from '@utils/setDYContext';
import { DYContext } from 'types/dynamicYield';
import { setIsDYContextSet } from 'src/redux/dyContextSlice';
import { useAppDispatch } from '@hooks/redux';
import { TrustPilotOverview, TrustPilotReview } from 'types/trustpilot';

import { gsap } from 'gsap/dist/gsap';

import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface pageProps {
  error: boolean;
  campaignBanner: campaign[] | [];
  isJestTesting?: boolean;
  homeBlogs: blogs['blogContents'];
  trustPilotOverview: TrustPilotOverview;
  trustPilotReviews: TrustPilotReview[];
}

declare const dataLayer: any;

const Home = ({
  // campaignBanner,
  isJestTesting,
  trustPilotOverview,
  trustPilotReviews,
  homeBlogs,
}: pageProps): React.ReactElement => {
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isDocumentReady && !isJestTesting) {
      dataLayer.push({
        ecomm_pagetype: 'homepage',
      });
    }
  }, [isJestTesting, isDocumentReady]);

  useEffect(() => {
    setDYContext(DYContext.Homepage);
    dispatch(setIsDYContextSet(true));
    setIsDocumentReady(true);
  }, []);

  useEffect(() => {
    gsap.from('.home-reviews', {
      opacity: 0,
      y: 80,
      duration: 0.5,
      delay: 0.5,
      ease: 'back.out(1.2)',
    });
  }, []);

  return (
    <>
      <Head>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.ironmongerydirect.co.uk" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org","@type": "WebSite","url": "https://www.ironmongerydirect.co.uk/","potentialAction": {"@type": "SearchAction","target": "https://www.ironmongerydirect.co.uk/search?query={search_term_string}","query-input": "required name=search_term_string"}}`,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org","@type": "HardwareStore","name": "IronmongeryDirect","image": "https://img-cdn.manutantraders.com/image/upload/shared/other/companyPremises.jpg","@id": "","url": "https://www.ironmongerydirect.co.uk/","telephone": "03003 038 821","address": {"@@type": "PostalAddress","streetAddress":"Scimitar Park, Courtauld Road","addressLocality": "Basildon","postalCode": "SS131ND","addressCountry": "GB"} ,"sameAs": ["https://www.facebook.com/IronmongeryDirect","https://twitter.com/IronmngryDirect","https://www.linkedin.com/company/ironmongery-direct-ltd/"],"openingHoursSpecification": {"@type": "OpeningHoursSpecification","dayOfWeek": ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],"opens": "07:00","closes": "20:00"}}`,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org","@type": "Organization","name": "IronmongeryDirect Limited","alternateName": "Ironmongery Direct","url": "https://www.ironmongerydirect.co.uk/","logo": "https://www.ironmongerydirect.co.uk/assets/images/ID_RGB_POSITIVE.jpg","sameAs": ["https://www.facebook.com/IronmongeryDirect","https://twitter.com/IronmngryDirect","https://www.linkedin.com/company/ironmongery-direct-ltd/"],"parentOrganization": {"@type": "Organization","@id": "https://www.manutan.com/en/homepage/our-vision/our-mission","name": "Manutan","description": "The Manutan group specialises in the distribution of equipment and supplies for companies and local authorities.","logo": ["https://www.manutan.com/medias/img/header_logo_en.png"],"url": "https://www.manutan.com/en/homepage/our-vision/our-mission"}}`,
          }}
        ></script>
        <meta
          name="description"
          content="Buy Door Furniture, Hinges, and more from the UK's largest Ironmongery range. With rapid Next Day Delivery & Free Returns as standard - all at great Trade Prices!"
        />
        <title>IronmongeryDirect | UK’s Biggest Range | Same Day Despatch</title>
      </Head>
      <main className="theme-id">
        <div className="bg-white-darker py-8 md:py-10 mb-12 overflow-hidden">
          <Discover />
          {/* <SearchBarHero
            companyID={companyID}
            // trustPilotOverview={trustPilotOverview}
            // trustPilotReviews={trustPilotReviews}
          /> */}
        </div>

        <CampaignTakeover />

        <div className="dy_homepage_recs mb-16 mx-8 sm:mx-12 xl:mx-32"></div>

        <CROTestimonials
          companyID={companyID}
          trustPilotOverview={trustPilotOverview}
          trustPilotReviews={trustPilotReviews}
        />

        <div id="dy_homepage_recs_0" className="my-16 mx-8 sm:mx-12 xl:mx-32"></div>
        <div className="dy_homepage_recs my-16 mx-8 sm:mx-12 xl:mx-32"></div>

        <div className="root-container my-16 px-0 md:px-12 xl:px-32">
          <HomeBrands />
        </div>

        <div className="dy_homepage_recs my-16 mx-8 sm:mx-12 xl:mx-32"></div>
        <div className="dy_homepage_recs my-16 mx-8 sm:mx-12 xl:mx-32"></div>

        {/* <div className="bg-white-darkest hidden">
          <HomeReasons companyID={companyID} />
        </div> */}

        <div className="hidden md:block my-16 py-8 sm:py-12 xl:py-16 bg-white-darker">
          <div className="root-container md:px-12 xl:px-32">
            <Bento />
          </div>
        </div>

        <div id="dy_homepage_recs_1" className="my-16 mx-8 sm:mx-12 xl:mx-32"></div>
        <div className="dy_homepage_recs my-16 mx-8 sm:mx-12 xl:mx-32"></div>

        <Blog companyID={companyID} blogs={homeBlogs} />

        <div id="dy_homepage_recs_2" className="my-16 mx-8 sm:mx-12 xl:mx-32"></div>
        <div className="dy_homepage_recs my-16 mx-8 sm:mx-12 xl:mx-32"></div>
        <div className="dy_homepage_recs my-16 mx-8 sm:mx-12 xl:mx-32"></div>
      </main>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const errorCall: boolean[] = [];
  let campaignBanner: campaign[] = [];
  let trustPilotReviewOverview: TrustPilotOverview = null;
  let homeBlogs: blogs['blogContents'] = [];
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const trustPilotApiKey = process.env.NEXT_TRUSTPILOT_API_KEY;
  const businessID = '4bdd414c0000640005060f21';

  await axios({
    url: `${
      process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL
    }/cms/Campaigns/HomepageCampaigns?page=1&pageSize=20&orderByColumn=first_publication_date&orderBy=desc&documentType=campaign&uid=${
      Number(process.env.NEXT_PUBLIC_COMPANY_ID) === Company.Ironmongery
        ? 'headline-test'
        : 'google'
    }&companyID=${companyID}`,
    headers: {
      universe:
        Number(process.env.NEXT_PUBLIC_COMPANY_ID) === Company.Ironmongery
          ? 'ironmongery'
          : 'electrical',
      'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY,
    },
  })
    .then((response) => {
      campaignBanner = response.data;
      errorCall.push(false);
    })
    .catch(() => {
      // [JM] : Causes ED homepage to return 500 internal server error
      // errorCall.push(true);
    });

  // START TRUSTPILOT

  await axios({
    method: 'GET',
    url: `https://api.trustpilot.com/v1/business-units/${businessID}`,
    headers: {
      apiKey: trustPilotApiKey,
    },
  }).then((response) => {
    trustPilotReviewOverview = response.data;
  });

  const trustPilotBusinessReviews = await axios({
    method: 'GET',
    url: `https://api.trustpilot.com/v1/business-units/${businessID}/reviews?stars=5&page=1&perPage=3`,
    headers: {
      apiKey: trustPilotApiKey,
    },
  }).then((response) => {
    const reviews: TrustPilotReview[] = response.data.reviews;
    const reviewWithProfilePic = reviews.map(async (review) => {
      let consumerProfile = null;
      await axios({
        method: 'GET',
        url: `https://api.trustpilot.com/v1/consumers/${review.consumer.id}/profile`,
        headers: {
          apiKey: trustPilotApiKey,
        },
      }).then((consumerResponse) => {
        consumerProfile = consumerResponse.data.profileImage.image73x73;
      });

      return {
        ...review,
        consumerProfile,
      };
    });

    const fullTrustPilotReview = Promise.all(reviewWithProfilePic).then((response) => {
      return response;
    });

    return fullTrustPilotReview;
  });

  // END TRUSTPILOT

  await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/cms/Blogs/GetHomePageBlogs?companyID=${companyID}`,
    headers: {
      // Universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
      'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        homeBlogs = response.data.slice(0, 4);
      } else {
        homeBlogs = [];
      }
    })
    .catch(() => {
      homeBlogs = [];
    });

  return {
    props: {
      error: errorCall.some((value) => value === true),
      campaignBanner: campaignBanner,
      trustPilotOverview: trustPilotReviewOverview,
      trustPilotReviews: trustPilotBusinessReviews,
      homeBlogs: homeBlogs,
    },
    revalidate: 86400,
  };
};

export default Home;
