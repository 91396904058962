import Carousel from '@components/Carousel';
import trustPilotFormatDate from '@utils/trustPilotFormatDate';
import Company from 'types/company';
import { TrustPilotOverview, TrustPilotReview } from 'types/trustpilot';

interface testimonialProps {
  companyID: Company;
  trustPilotOverview: TrustPilotOverview;
  trustPilotReviews: TrustPilotReview[];
}

const CROTestimonials = ({
  trustPilotOverview,
  trustPilotReviews,
}: testimonialProps): JSX.Element => {
  return (
    // <section className="home-testimonials py-10 xl:py-15">
    //   <div className="home-testimonials__inner flex-col">
    //     <div className="">
    //       <div className="mb-10 xl:mb-15">
    //         <h2 className="text-2xl text-grey-dark font-normal leading-normal mt-12 mb-4">
    //           <strong className="text-black-lighter font-bold">Rated excellent</strong> by customers
    //         </h2>
    //         {/* <h2 className="text-black-lighter">Rated Excellent by our customers</h2> */}
    //       </div>
    //     </div>
    //     <div className="home-testimonials__body">
    //       <Carousel
    //         companyID={1}
    //         direction={'x'}
    //         content={trustPilotReviews.map((testimony, index) => (
    //           <div
    //             className="mt-4 w-full min-w-full md:min-w-1/2 xl:min-w-1/3 carousel__slide"
    //             key={`cust-testimony-${index}`}
    //           >
    //             <div className="absolute z-1 -top-4 w-full flex justify-center">
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="150px"
    //                 height="30px"
    //                 viewBox="0 0 512 96"
    //                 version="1.1"
    //               >
    //                 <g
    //                   id="Trustpilot_ratings_5star-RGB"
    //                   stroke="none"
    //                   strokeWidth="1"
    //                   fill="none"
    //                   fillRule="evenodd"
    //                 >
    //                   <g fillRule="nonzero">
    //                     <rect
    //                       id="Rectangle-path"
    //                       fill="#00B67A"
    //                       x="0"
    //                       y="0"
    //                       width="96"
    //                       height="96"
    //                     />
    //                     <rect
    //                       id="Rectangle-path"
    //                       fill="#00B67A"
    //                       x="104"
    //                       y="0"
    //                       width="96"
    //                       height="96"
    //                     />
    //                     <rect
    //                       id="Rectangle-path"
    //                       fill="#00B67A"
    //                       x="208"
    //                       y="0"
    //                       width="96"
    //                       height="96"
    //                     />
    //                     <rect
    //                       id="Rectangle-path"
    //                       fill="#00B67A"
    //                       x="312"
    //                       y="0"
    //                       width="96"
    //                       height="96"
    //                     />
    //                     <rect
    //                       id="Rectangle-path"
    //                       fill="#00B67A"
    //                       x="416"
    //                       y="0"
    //                       width="96"
    //                       height="96"
    //                     />
    //                     <path
    //                       d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z"
    //                       id="Shape"
    //                       fill="#FFFFFF"
    //                     />
    //                     <path
    //                       d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z"
    //                       id="Shape"
    //                       fill="#FFFFFF"
    //                     />
    //                     <path
    //                       d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z"
    //                       id="Shape"
    //                       fill="#FFFFFF"
    //                     />
    //                     <path
    //                       d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z"
    //                       id="Shape"
    //                       fill="#FFFFFF"
    //                     />
    //                     <path
    //                       d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z"
    //                       id="Shape"
    //                       fill="#FFFFFF"
    //                     />
    //                   </g>
    //                 </g>
    //               </svg>
    //             </div>
    //             <div className="testimonial max-w-full min-w-full w-full">
    //               <div className="testimonial__inner">
    //                 <div className="testimonial__content mb-0">
    //                   <div className="flex w-full items-center mb-5">
    //                     <img
    //                       src={testimony.consumerProfile.url}
    //                       alt="Profile"
    //                       height={48}
    //                       width={48}
    //                       className="irm-1-5-review__client__avatar h-12 w-12 mr-3"
    //                     />
    //                     <div className="flex flex-col">
    //                       <p className="mb-2">{testimony.consumer.displayName}</p>
    //                       <p className="mb-0 text-grey-light">
    //                         {testimony.updatedAt
    //                           ? trustPilotFormatDate(testimony.updatedAt)
    //                           : trustPilotFormatDate(testimony.createdAt)}
    //                       </p>
    //                     </div>
    //                   </div>
    //                   <h3>{testimony.title}</h3>

    //                   <p>{testimony.text}</p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //       />
    //       <div className="flex flex-col items-center mt-10 xl:mt-15">
    //         <p>
    //           Rated {trustPilotOverview.score.trustScore}/5 based on{' '}
    //           <a
    //             href="https://uk.trustpilot.com/review/www.ironmongerydirect.co.uk"
    //             rel="noopener noreferrer"
    //             target="_blank"
    //             className="underline"
    //           >
    //             {trustPilotOverview.numberOfReviews.total.toLocaleString()}
    //           </a>{' '}
    //           reviews
    //         </p>
    //         <img
    //           src="https://img-cdn.manutantraders.com/image/upload/w_150,q_auto,f_auto/shared/homepage/rebrand/trustpilot_v2_2x.png"
    //           loading="lazy"
    //           alt="Trustpilot Logo"
    //           width="126"
    //           height="31"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section className="root-container mx-auto px-8 my-16 sm:px-12 xl:px-32 home-reviews flex-col md:flex-row gap-6 flex-nowrap text-black-lightest items-stretch relative z-1 flex overflow-hidden ">
      <div className="flex items-center md:block flex-row flex-wrap w-full md:w-1/3 lg:w-1/4 self-center">
        <h2 className="w-2/3 md:w-auto text-2xl md:text-3xl text-grey-dark font-normal leading-tight mb-2 md:mb-4 twrap-balance">
          <strong className="font-bold" style={{ color: '#00b67a' }}>
            Rated excellent
          </strong>{' '}
          by {trustPilotOverview.numberOfReviews.total.toLocaleString()} customers
        </h2>
        <div className="w-1/3 md:w-auto">
          <img
            src="https://img-cdn.manutantraders.com/image/upload/w_150,q_auto,f_auto/shared/homepage/rebrand/trustpilot_v2_2x.png"
            loading="lazy"
            alt="Trustpilot Logo"
            width="126"
            height="52"
            className="mb-0 float-right md:float-none"
          />
        </div>
        <p className="hidden md:block text-grey-dark m-0 leading-tight">
          {trustPilotOverview.score.trustScore}/5 based on{' '}
          <a
            href="https://uk.trustpilot.com/review/www.ironmongerydirect.co.uk"
            rel="noopener noreferrer"
            target="_blank"
            className="underline"
          >
            {trustPilotOverview.numberOfReviews.total.toLocaleString()}
          </a>{' '}
          reviews
        </p>
      </div>
      <Carousel
        companyID={1}
        direction={'x'}
        showCarouselOverflow={true}
        hidePrevNextButtons={true}
        draggable={true}
        mainWrapperClass="mt-0 w-full md:w-2/3 lg:w-3/4"
        mainContainerClass={'flex flex-row gap-6'}
        content={trustPilotReviews.map((testimony, key) => (
          <div
            className="flex-grow-0 flex-shrink-0 md:flex-shrink w-2/3 md:w-2/5 lg:w-1/3 items-stretch flex flex-col rounded-2xl p-8 bg-white-darker text-center twrap-balance"
            key={key}
          >
            <div className="w-full flex justify-center mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150px"
                height="30px"
                viewBox="0 0 512 96"
                version="1.1"
              >
                <g
                  id="Trustpilot_ratings_5star-RGB"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g fillRule="nonzero">
                    <rect id="Rectangle-path" fill="#00B67A" x="0" y="0" width="96" height="96" />
                    <rect id="Rectangle-path" fill="#00B67A" x="104" y="0" width="96" height="96" />
                    <rect id="Rectangle-path" fill="#00B67A" x="208" y="0" width="96" height="96" />
                    <rect id="Rectangle-path" fill="#00B67A" x="312" y="0" width="96" height="96" />
                    <rect id="Rectangle-path" fill="#00B67A" x="416" y="0" width="96" height="96" />
                    <path
                      d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h3 className="my-6 text-2xl">{testimony.title}</h3>
            <div className="mt-0">
              {/* <p>{testimony.text}</p> */}
              <div className="">
                <p className="">{testimony.consumer.displayName}</p>
                <p className="text-grey-dark text-sm">
                  {testimony.updatedAt
                    ? trustPilotFormatDate(testimony.updatedAt)
                    : trustPilotFormatDate(testimony.createdAt)}
                </p>
              </div>
            </div>
          </div>
        ))}
      />
    </section>
  );
};

export default CROTestimonials;
