import Link from 'next/link';
import { useEffect } from 'react';
import Carousel from '@components/Carousel';

import { gsap } from 'gsap/dist/gsap';

import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const HomeBrands = (): JSX.Element => {
  const brandList = [
    {
      link: '/brands/spax',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/spax.png',
      name: 'Spax',
    },
    {
      link: '/brands/blum',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/blum.png',
      name: 'Blum',
    },
    {
      link: '/brands/briton',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/briton.png',
      name: 'Briton',
    },
    {
      link: '/brands/yale',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/yale.png',
      name: 'Yale',
    },
    {
      link: '/brands/arrone',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/arrone.png',
      name: 'Arrone',
    },
    {
      link: '/brands/dorgard',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/dorgard.png',
      name: 'Dorgard',
    },
    {
      link: '/brands/eclisse',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/eclisse.png',
      name: 'Eclisse',
    },
    {
      link: '/brands/Elfa',
      logoUrl:
        'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/brands/product-page/elfa.png',
      name: 'Elfa',
    },
  ];

  useEffect(() => {
    const btl = gsap.timeline({
      scrollTrigger: {
        trigger: '.home-brands',
        start: 'top bottom-=150px',
      },
      defaults: {
        ease: 'back.out(1.2)',
        duration: 1,
      },
    });

    btl
      .from('.home-brands', {
        opacity: 0,
      })
      .from('.home-brands div h2', {
        delay: 0,
        opacity: 0,
        y: 60,
      })
      .from('.home-brands div.grid div', {
        delay: 0.1,
        opacity: 0,
        scale: 1.2,
        y: -80,
        stagger: 0.1,
        duration: 1,
        ease: 'power2.out',
      });
  }, []);

  return (
    <section className="home-brands bg-orange my-12 md:rounded-2xl lg:px-20 p-12 xxl:px-32 md:hp-shadow overflow-hidden">
      <div className="w-full text-center mb-8">
        <h2 className="font-normal text-2xl md:text-3xl">
          Stocking the best{' '}
          <Link href="/brands">
            <a className="text-white font-bold all-brands-link">ironmongery</a>
          </Link>{' '}
          brands
        </h2>
      </div>
      <Carousel
        companyID={1}
        direction={'x'}
        showCarouselOverflow={true}
        hidePrevNextButtons={true}
        draggable={true}
        mainWrapperClass="mt-0"
        mainContainerClass={'grid grid-flow-col gap-6 xl:grid-cols-8'}
        content={brandList.map((brand, key) => (
          <div
            key={key}
            className="w-auto"
            style={{ aspectRatio: '1', maxHeight: '100px', minWidth: '100px' }}
          >
            <Link href={brand.link.toLowerCase()}>
              <a
                className="p-4 lg:p-4 bg-white rounded-full block overflow-hidden w-full h-full hp-shadow hp-zoom"
                role="button"
                tabIndex={0}
                title={brand.name}
              >
                <img
                  src={brand.logoUrl}
                  data-lazy-src={brand.logoUrl}
                  alt={brand.name}
                  width="150"
                  height="150"
                  className="w-full h-full"
                />
              </a>
            </Link>
          </div>
        ))}
      />
    </section>
  );
};

export default HomeBrands;
