function Bento() {
  return (
    <>
      <section className="home-find p-0">
        <div className="home-find__inner flex flex-wrap lg:flex-no-wrap flex-row lg:gap-6 w-full">
          <div className="flex flex-initial order-1 w-full sm:w-1/2 lg:w-2/6 sm:pr-3 lg:pr-0">
            {/* Price Drops */}
            <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl bg-cover bg-center hp-shadow hp-zoom flex justify-center"
              style={{
                aspectRatio: '1/1',
                backgroundImage: `url("https://img-cdn.manutantraders.com/image/upload/v1729694766/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/Price-Drops-4.jpg")`,
              }}
            >
              <a
                href="/search?query=PricesDropped&utm_source=Homepage&utm_medium=Secondary&utm_campaign=Price+Drops"
                className="rounded-full bg-transparent text-white border-1 border-white text-sm xl:text-base py-2 px-4 self-end mb-4 xl:mb-8 text-center hover:text-orange hover:border-orange"
              >
                Shop now & save!
              </a>
            </div>
          </div>
          <div className="flex flex-initial order-3 lg:order-2 flex-col sm:flex-row lg:flex-col w-full lg:w-2/6 gap-6 mt-6 lg:mt-0">
            {/* Refer a Friend */}
            <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl bg-cover bg-center hp-shadow hp-zoom"
              style={{
                aspectRatio: '2/1',
                backgroundImage:
                  'url(https://img-cdn.manutantraders.com/image/upload/v1729695911/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/Refer-a-friend-web-background-600x300.jpg)',
              }}
            >
              <div className="flex flex-col w-7/12 justify-center h-full pl-4">
                <h4 className="text-white font-black text-xl xl:text-26px mb-2">
                  It pays to refer
                </h4>
                <p className="text-black text-xl xl:text-26px -mb-1">
                  Get <span className="font-black">20% OFF</span>
                </p>
                <p className="text-black text-xs xl:text-sm mb-4">for you and your friend.</p>
                <a
                  href="/refer?situation=websecondary&utm_source=Homepage&utm_medium=Secondary&utm_campaign=Refer+a+friend"
                  className="rounded-full bg-transparent text-black border-1 border-black py-2 px-4 w-150px text-sm xl:text-base text-center hover:text-white hover:bg-black"
                >
                  Refer a friend
                </a>
              </div>
            </div>
            {/* Catalogue */}
            <div
              className="campaign w-full h-auto rounded-2xl hp-shadow hp-zoom bg-cover bg-no-repeat bg-center"
              style={{
                aspectRatio: '2/1',
                backgroundImage:
                  'url(https://img-cdn.manutantraders.com/image/upload/v1729695502/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/C138-web-background-600x300.jpg',
              }}
            >
              <div className="flex flex-col w-7/12 justify-center h-full pl-4">
                <h4 className="text-orange font-black text-xl xl:text-26px xl:mb-2">
                  Your project
                </h4>
                <h4 className="text-white font-black text-xl xl:text-26px mb-2 xl:mb-4">
                  starts here
                </h4>
                <p className="text-white text-sm mb-4">Order our latest catalogue.</p>
                <a
                  href="/free-catalogue?utm_source=Homepage&utm_medium=Secondary&utm_campaign=C138+Catalogue"
                  className="rounded-full bg-transparent text-white border-1 border-white text-sm xl:text-base py-2 px-4 w-175px xl:w-200px text-center hover:text-orange hover:border-orange"
                >
                  Get your FREE copy!
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-initial order-2 w-full sm:w-1/2 lg:w-2/6 lg:order-3 sm:pl-3 lg:pl-0">
            {/* Click & Collect */}
            <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl bg-cover bg-center hp-shadow hp-zoom flex flex-col justify-between"
              style={{
                aspectRatio: '1/1',
                backgroundImage:
                  'url(https://img-cdn.manutantraders.com/image/upload/v1729868143/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/Click-_-Collect-web-background-600x600.jpg)',
              }}
            >
              <div className="mt-8 flex flex-col items-center">
                <p className="text-white text-3xl xl:text-4xl font-black -mt-2">No one home?</p>
                <p className="text-orange text-3xl xl:text-4xl font-black -mt-2">No problem!</p>
              </div>
              <div className="mb-4 xl:mb-8 flex flex-col items-center">
                <p className="mb-6">Over 10,000 locations nationwide!</p>
                <a
                  href="/information/delivery?utm_source=Homepage&utm_medium=Secondary&utm_campaign=Click+and+Collect"
                  className="rounded-full bg-transparent text-white border-1 border-white text-sm xl:text-base py-2 px-4 h-10 hover:text-orange hover:border-orange"
                >
                  Find out more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bento;
