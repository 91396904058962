function CampaignTakeover(): JSX.Element {
  const bannerImages = {
    desktop:
      'https://img-cdn.manutantraders.com/image/upload/v1728546998/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/Prize-Draw-Trade-Xtra-Desktop-2377x800-Optimised.jpg',
    tablet:
      'https://img-cdn.manutantraders.com/image/upload/v1728550306/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/Prize-Draw-TradeXtra-Tablet-768x277-Optimised.jpg',
    mobile:
      'https://img-cdn.manutantraders.com/image/upload/v1728550306/ironmongery/homepage/Homepage_banners_2024/HB_Oct_24/Prize-Draw-TradeXtra-Mobile-360x311-Optimised.jpg',
  };

  const bannerLink =
    '/tradextra?utm_source=Homepage&utm_medium=Primary&utm_campaign=TradeXtra+Comp';

  return (
    <div className="root-container px-8 my-16 sm:px-12 xl:px-32">
      <div className="flex flex-col md:flex-row md:space-x-5">
        <div className="z-10 w-full md:w-3/4 flex">
          <a href={bannerLink}>
            <img
              src={bannerImages.desktop}
              alt="event-desktop"
              className="w-full h-full mx-auto rounded-2xl hidden xl:block"
            />
            <img
              src={bannerImages.tablet}
              alt="event-tablet"
              className="w-full h-full mx-auto rounded-2xl hidden md:block xl:hidden"
            />
            <img
              src={bannerImages.mobile}
              alt="event-mobile"
              className="w-full h-full mx-auto rounded-2xl block md:hidden"
            />
          </a>
        </div>
        <div className="w-full md:w-1/4 flex">
          <a href="/search?query=BritonPanicPromo&utm_source=Homepage&utm_medium=Primary&utm_campaign=Briton+Panic">
            <img
              src="https://img-cdn.manutantraders.com/image/upload/v1733500427/ironmongery/homepage/Homepage_banners_2024/HB_Dec_24/Briton-exit-hardware-promo-Primary-Small.jpg"
              className="rounded-2xl mt-5 md:mt-0 mx-auto w-full"
              alt="small-banner"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CampaignTakeover;
